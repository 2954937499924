<template>
  <Layout>
    <!--==================================================-->
    <!-- Start lawyern breatcam section  -->
    <!--==================================================-->
    <div class="service breatcam-section d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breatcam-content text-center">
              <!-- breadcumb title -->
              <div class="breatcam-title">
                <h1> {{ $t('header.nav.businessAreas') }} </h1>
              </div>
              <!-- breatcam menu -->
<!--              <div class="breatcam-menu">-->
<!--                <ul>-->
<!--                  <li><a @click="$router.push('/')"> {{ $t('header.nav.home') }} </a></li>-->
<!--                  <li> {{ $t('header.nav.businessAreas') }} </li>-->
<!--                </ul>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--==================================================-->
    <!-- End lawyern breatcam section -->
    <!--==================================================-->







    <!--==================================================-->
    <!-- Start lawyer service section  -->
    <!--==================================================-->
    <div class="service-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="lawyer-section-title pb-60 text-center" data-aos="fade-up">
              <h1>
                <img src="@/assets/scss/page/image/home/icon-1.png">
                <span class="chinese-text">{{ $t('page.home.text3') }}</span>
<!--                <span class="english-text">{{ $t('page.home.text4') }}</span>-->
              </h1>
<!--              <p>{{ $t('page.home.text5') }}</p>-->
            </div>
          </div>
        </div>
        <div class="row" style="padding-bottom: 300px;">
          <div class="col-lg-3 col-md-6" v-for="(item, index) in list" :key="index">
            <div class="lawyer-service-single-box" data-aos="fade-up" data-aos-delay="100">
              <!-- service icon -->
              <div class="service-icon">
                <img :src="item.icon || defaultIcon" alt="">
              </div>
              <!-- donate content -->
              <div class="lawyer-service-content">
                <!-- service title -->
                <div class="service-title">
                  <h3>{{ $localeLang==='zh'? item.name : item.name_en }}</h3>
                </div>
                <!-- service description -->
                <div class="service-desc">
                  <p>{{ item.intro }}</p>
                </div>
                <!-- service button -->
                <div class="service-button">
                  <a class="ser" @click="$router.push(`/service-detail?id=${item.id}`)">{{ $t('page.home.了解更多') }}...</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--==================================================-->
    <!-- End lawyer service Section -->
    <!--==================================================-->
  </Layout>
</template>

<script>
import Layout from "@/components/common/Layout.vue";


export default {
  name: 'Demo',
  components: {Layout},
  data() {
    return {
      defaultIcon: require('@/assets/scss/page/image/home/icon-2.png'),
      list: []
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$request({
        url: 'api/pc/domain-list',
        data: ''
      }).then(res => {
        this.list = res.data;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.service-icon{
  img{
    width: 50px;
  }
}
</style>
