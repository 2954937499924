<template>
  <Layout>
    <!--==================================================-->
    <!-- Start lawyern breatcam section  -->
    <!--==================================================-->
    <div class="about breatcam-section d-flex align-items-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breatcam-content text-center">
              <!-- breadcumb title -->
              <div class="breatcam-title">
                <h1> {{ $t('page.about.title') }} </h1>
              </div>
              <!-- breatcam menu -->
              <!--              <div class="breatcam-menu">-->
              <!--                <ul>-->
              <!--                  <li><a @click="$router.push('/')"> {{ $t('page.about.home') }} </a></li>-->
              <!--                  <li> {{ $t('page.about.title') }} </li>-->
              <!--                </ul>-->
              <!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--==================================================-->
    <!-- End lawyern breatcam section -->
    <!--==================================================-->


    <!--==================================================-->
    <!-- Start lawyer service section  -->
    <!--==================================================-->


        <div class="container">
          <div class="container-left">
            <div class="about-title" v-if="currentIdx!=0">{{ list[currentIdx].name }}</div>
            <div class="about-detail">
              <div v-html="list[currentIdx].content">{{ list[currentIdx].content }}</div>
            </div>
          </div>
          <div class="container-right">
            <div class="container-right-menu">
              <div v-for="(item,index) in list" >
                <div v-if="index!=0" class="container-right-menu-item" :class="{active: currentIdx === index}" @click="currentIdx=index">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>

    <!--==================================================-->
    <!-- End lawyer service Section -->
    <!--==================================================-->
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'

export default {
  name: 'AboutDetail',
  components: {
    Layout
  },
  data() {
    return {
      list: [{}],
      currentIdx: 0
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$request({
        url: 'api/pc/new-about-us',
        data: {}
      }).then(res => {
        let temp = res.data;
        for(let i=0;i<res.data.length;i++){
          temp[i].content && (temp[i].content = temp[i].content.replace(/\<img/gi, '<img style="max-width:100%;height:auto" '));
        }
        this.list = temp
      })
    }
  }
}
</script>
<style lang="scss" scoped>

  //background: linear-gradient(to right, #ffffff 50%, #F7F7FB 50%);

  .container {
    display: flex;

    .container-left {
      padding: 100px 0;
      flex: 1;
      //background: white;
      min-height: 800px;
      padding-right: 80px;

      .about-title{
        font-size: 1.2em;
        margin-bottom: 40px;
      }

      .about-detail {
        width: 100%;
      }

    }

    .container-right {
      padding-top: 100px;
      width: 300px;
      .container-right-menu{
        border-left: 1px solid #d7d7d7;
        padding-left: 68px;
        &-item{
          font-size: 1.1em;
          padding: 15px 0;
          cursor: pointer;
          //border-bottom: 1px solid #e1e1e1;
          &:hover{
            color: var(--color-main);
          }
        }
        &-item.active{
          color: var(--color-main);
        }
      }
    }
  }

@media (min-width: 768px) {
  .container .container-right{
    width: 200px;
  }
}
</style>
