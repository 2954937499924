import Vue from 'vue'
import VueRouter from 'vue-router'
import Demo from "@/views/Demo.vue";
import About from '@/views/About'
import AboutDetail from '@/views/AboutDetail.vue'
import Service from '@/views/Service'
import ServiceDetail from "@/views/ServiceDetail.vue";
import Lawyer from "@/views/Lawyer.vue";
import LawyerDetail from "@/views/LawyerDetail.vue";
import Join from "@/views/Join.vue";
import JoinDetail from "@/views/JoinDetail.vue";
import Honor from "@/views/Honor.vue";
import Contact from "@/views/Contact.vue";
import News from "@/views/News.vue";
import NewsDetail from "@/views/NewsDetail.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Demo',
    component: Demo,
    meta: {
      title: '广东君言律师事务所',
    },
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: About,
  //   meta: {
  //     title: '关于我们-广东君言律师事务所',
  //   },
  // },
  {
    path: '/about',
    name: 'AboutDetail',
    component: AboutDetail,
    meta: {
      title: '关于我们-广东君言律师事务所',
    },
  },
  {
    path: '/service',
    name: 'Service',
    component: Service,
    meta: {
      title: '业务领域-广东君言律师事务所',
    },
  }, {
    path: '/service-detail',
    name: 'ServiceDetail',
    component: ServiceDetail,
    meta: {
      title: '业务详情-广东君言律师事务所',
    },
  },
  {
    path: '/lawyer',
    name: 'Lawyer',
    component: Lawyer,
    meta: {
      title: '律师团队-广东君言律师事务所',
    },
  },
  {
    path: '/lawyer-detail',
    name: 'LawyerDetail',
    component: LawyerDetail,
    meta: {
      title: '律师信息-广东君言律师事务所',
    },
  },
  {
    path: '/join',
    name: 'Join',
    component: Join,
    meta: {
      title: '加入我们-广东君言律师事务所',
    },
  },
  {
    path: '/join-detail',
    name: 'JoinDetail',
    component: JoinDetail,
    meta: {
      title: '岗位详情-广东君言律师事务所',
    },
  },
  {
    path: '/honor',
    name: 'Honor',
    component: Honor,
    meta: {
      title: '律所荣耀-广东君言律师事务所',
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      title: '联系我们-广东君言律师事务所',
    },
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    meta: {
      title: '新闻动态-广东君言律师事务所',
    },
  },
  {
    path: '/news-detail',
    name: 'NewsDetail',
    component: NewsDetail,
    meta: {
      title: '资讯详情-广东君言律师事务所',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
  window.scrollTo(0, 0)
});

export default router
