<template>
    <main class="page-wrapper" :style="{fontFamily: $localeLang==='zh' ? '黑体, Arial, sans-serif':'Montserrat, Arial, sans-serif'}">
        <Header/>
        <div class="main-content">
            <slot></slot>
        </div>
        <FooterTwo/>
    </main>
</template>

<script>
    import Header from "@/components/common/header/Header.vue";
    import Footer from "@/components/common/footer/Footer.vue";
    import FooterTwo from "@/components/common/footer/FooterTwo.vue";

    export default {
        name: 'Layout',
        components: {Header, Footer, FooterTwo},
        props: {

        }
    }
</script>
