import axios from 'axios';
import Vue from "vue";
// const domain = 'https://jytt.xinhankr.com/web/index.php?r=';
const domain = 'https://www.junyanlawyer.com/web/index.php?r=';
// 创建axios实例
const request = axios.create({
  baseURL: domain, // api 的 base_url
  timeout: 50000 // 请求超时时间
})

function mixAndEncrypt(str1, str2) {
  let mixedStr = '';
  let str1Index = 0;
  let str2Index = 0;

  // Step 1: Mix two strings
  while (str1Index < str1.length || str2Index < str2.length) {
    if (str1Index < str1.length) {
      mixedStr += str1[str1Index++];
    }
    if (str2Index < str2.length) {
      mixedStr += str2[str2Index++];
    }
  }

  // Step 2: Reverse the mixed string
  let reversedStr = mixedStr.split('').reverse().join('');

  // Step 3: Swap pairs of characters
  let swappedStr = '';
  for (let i = 0; i < reversedStr.length; i += 4) {
    swappedStr += reversedStr.substring(i + 2, i + 4) + reversedStr.substring(i, i + 2);
  }

  return swappedStr;
}

// request拦截器
request.interceptors.request.use(
  config => {
    let timestamp = Date.now() + '';
    config.data['token'] = mixAndEncrypt('K7pR4Q6wzE9tY',timestamp);
    config.data['timestamp'] = timestamp
    config.headers['x-language'] = Vue.prototype.$localeLang;
    return config;
  },
  error => {
    // Do something with request error
    // console.log(error); // for debug
    Promise.reject(error);
  }
)

// response 拦截器
request.interceptors.response.use(
  response => {
    /**
     * code为非0是抛错
     */
    const res = response.data
    if (res.code !== 0) {
      // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
      if (res.code === 201) {
          location.reload() // 为了重新实例化vue-router对象 避免bug
      }
      return Promise.reject(res)
    } else {
      return response.data
    }
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

export function getConnect({url, data, type}) {
  return new Promise((resolve, reject) => {
    request({url: url, method: type || 'post', data: data || {}})
      .then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}
