<template>
  <div>
    <!--==================================================-->
    <!-- Start lawyer Footer Section -->
    <!--==================================================-->
    <div class="footer-two-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-4">
            <span @click="bindToGov">{{ $t('footer.copyrightText') }}</span>
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="text-center footer-two-menu">
              <span @click="$router.push('/about')">{{ $t('header.nav.about') }}</span>　|　<span @click="$router.push('/contact')">{{ $t('header.nav.contact') }}</span>　|　<span @click="$router.push('/join')">{{ $t('header.nav.join') }}</span>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 text-right">
            <el-tooltip class="ml-20" effect="dark" placement="top">
              <div slot="content">
                <img style="width: 160px;height: 160px;" src="@/assets/scss/page/image/common/code.png"></img>
              </div>
              <img src="@/assets/images/footer/01.png">
            </el-tooltip>
            <el-tooltip class="ml-20" effect="dark" placement="top">
              <div slot="content">
                <img style="width: 160px;height: 160px;" src="@/assets/images/footer/gzh.jpg"></img>
              </div>
              <img src="@/assets/images/footer/02.png">
            </el-tooltip>
            <el-tooltip class="ml-20" effect="dark" placement="top">
              <div slot="content">
                <img style="width: 160px;height: 160px;" src="@/assets/images/footer/video.png"></img>
              </div>
              <img src="@/assets/images/footer/03.png">
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <!--==================================================-->
    <!-- End lawyer Footer Section -->
    <!--==================================================-->
    <ScrollTop/>
  </div>
</template>

<script>
import ScrollTop from "@/components/common/footer/ScrollTop.vue";
import {Tooltip as ElTooltip} from "element-ui";

export default {
  name: 'Footer',
  components: {ScrollTop, ElTooltip},
  props: {
    data: {
      default: null
    }
  },
  methods: {
    bindToGov() {
      window.open('https://beian.miit.gov.cn/', '_blank');
    }
  }
}
</script>
