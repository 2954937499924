<template>
  <div>
    <!--==================================================-->
    <!-- Start lawyer Footer Section -->
    <!--==================================================-->
    <div class="footer-section">
      <div class="container">
        <div class="row footer-bg">
          <div class="col-lg-4 col-md-6 pl-0 pr-0">
            <div class="widgets-company-info">
              <!-- lawyer logo -->
              <div class="lawyer-logo">
                <a class="logo_img" href="" title="techno">
                  <img src="@/assets/images/logo/logo.png" alt="">
                </a>
              </div>
              <!-- company desc info -->
              <div class="company-desc-info">
                <p><i class="bi bi-telephone"></i> {{ $t('footer.phone') }} 0755-83023939 </p>
                <p><i class="bi bi-envelope-fill"></i> {{ $t('footer.email') }} junyan@junyanlawyer.com </p>
                <p><i class="bi bi-geo-alt-fill"></i> 中国深圳市南山区大冲国际中心29层 </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 pl-30">
            <div class="widget-nav-menu">
              <!-- widget title -->
              <h4 class="widget-title"> {{ $t('footer.businessAreas') }} </h4>
              <div class="menu-quick-link-content">
                <ul class="footer-widget-list">
                  <li><a class="ws" @click="$router.push('/service-detail?id=11')"> {{ $t('footer.民商事争议解决') }} </a></li>
                  <li><a class="ws" @click="$router.push('/service-detail?id=6')"> {{ $t('footer.刑事辩护与刑事合规') }} </a></li>
                  <li><a class="ws" @click="$router.push('/service-detail?id=17')"> {{ $t('footer.房地产与建筑工程') }} </a></li>
                  <li><a class="border1" @click="$router.push('/service-detail?id=8')"> {{ $t('footer.婚姻家事与财富管理') }} </a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="widget-nav-menu">
              <!-- widget title -->
              <h4 class="widget-title"> {{ $t('footer.news') }} </h4>
              <div class="menu-quick-link-content">
                <ul class="footer-widget-list">
                  <li><a class="ws" @click="$router.push('/news')"> {{ $t('footer.news') }} </a></li>
<!--                  <li><a href="#"> {{  $t('footer.君言业绩') }} </a></li>-->
                  <li><a class="ws" @click="$router.push('/news?type=2')"> {{ $t('footer.君言荣誉') }} </a></li>
                  <li><a class="ws" @click="$router.push('/news?type=3')"> {{ $t('footer.君言观点') }} </a></li>
                  <li><a class="border1" @click="$router.push('/news?type=4')"> {{ $t('footer.君言人文') }} </a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-6">
            <div class="widget-nav-menu">
              <!-- widget title -->
              <h4 class="widget-title"> </h4>
<!--              <h4 class="widget-title"> {{ $t('footer.about') }} </h4>-->
<!--              <div class="menu-quick-link-content">-->
<!--                <ul class="footer-widget-list">-->
<!--                  <li><a href="#"> {{ $t('footer.君言简介') }} </a></li>-->
<!--                  <li><a href="#"> {{ $t('footer.加入君言') }} </a></li>-->
<!--                </ul>-->
<!--              </div>-->
              <div class="menu-quick-link-contact">
                <img src="@/assets/scss/page/image/common/code.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="row footer-bottom-bg">
            <div class="footer-bottom-content-copy">
              <a href="https://beian.miit.gov.cn/"><p>{{ $t('footer.copyrightText') }}</p></a>
            </div>
        </div>
      </div>
    </div>
    <!--==================================================-->
    <!-- End lawyer Footer Section -->
    <!--==================================================-->
    <ScrollTop/>
  </div>
</template>

<script>
import ScrollTop from "@/components/common/footer/ScrollTop.vue";

export default {
  name: 'Footer',
  components: {ScrollTop},
  props: {
    data: {
      default: null
    }
  }
}
</script>
